body {
  margin: 0;
}

#root {
  width: 100%;
  display: flex;
  justify-content: center;
}

html,
body,
#root {
  background-color: #fafbfc;
}
